export type Doc = {
  id: number
  author: string[]
  post: string[]
  link: string
}

export type Client = {
  id: number
  title: string[]
  description: string[]
  logo: string
  docs: Doc[]
}

export const clients: Client[] = [
  {
    id: 1,
    title: ['Integral Polymedia Systems LLC', 'Integral Polymedia Systems LLC'],
    description: [
      'В 2023 году компания TC UAE оказывала нам услуги, связанные с юридическим сопровождением сделки по приобретению активов на территории ОАЭ. Мы рекомендуем TC UAE в качестве надежного и профессионального партнера. ',
      'In 2023 TC UAE provided us with consulting services related to legal assistance regarding asset purchase in the UAE. The Company recommends TC UAE as a reliable partner and professional advisor in the UAE. '
    ],
    docs: [
      {
        id: 1,
        author: ['Елена Новикова', 'Elena Novikova'],
        post: ['Партнер Integral Polymedia Systems LLC', 'Partner at Integral Polymedia Systems LLC'],
        link: 'https://taxcompliance.su/Letter_TaxCompl.jpg'
      }
    ],
    logo: require('./assets/polymedia.jpeg')
  },
  {
    id: 2,
    title: ['Lestate LLC', 'Lestate LLC'],
    description: [
      'В 2023 году компания TC UAE оказывала нам консультационные услуги, связанные с организацией и сопровождением бизнеса на территории ОАЭ. Мы выражаем благодарность TC UAE и ее специалистам Управляющему партнеру М.А. Бегунову и Директору ТС UAE Н.В. Жарову за своевременно и качественно оказанные консультационные услуги.',
      'In 2023 TC UAE provided us with consulting services on establishment and legal support of business in the UAE. We express our gratitude to TC UAE, as well as its specialists Managing Partner M. Begunov and Director of TC UAE N. Zharov for timely and qualitative consulting services.'
    ],
    docs: [
      {
        id: 1,
        author: ['Т.В. Кундюков', 'T. Kundyukov'],
        post: ['Генеральный директор компании «Лестейт»', 'CEO of Lestate'],
        link: 'https://taxcompliance.su/LeState_Благодарственное%20письмо.pdf'
      }
    ],
    logo: require('./assets/lestate.jpg')
  },
  {
    id: 3,
    title: ['Pharm-Sintez JSC', 'Pharm-Sintez JSC'],
    description: [
      'В 2023 году компания TC UAE оказывала в адрес АО «Фарм-Синтез» и его взаимозависимых лиц консультационные услуги в области организации бизнеса и налогообложения на территории ОАЭ. Мы выражаем благодарность TC UAE и ее специалистам Управляющему партнеру М.А. Бегунову и Директору ТС UAE Н.В. Жарову за своевременно и качественно оказанные консультационные услуги.',
      'In 2023 TC UAE provided Pharm-Sintez JSC and its interdependents with consulting services in the field of business organisation and taxation in the UAE. We express our gratitude to TC UAE, as well as its specialists Managing Partner M. Begunov and Director of TC UAE N. Zharov for timely and qualitative consulting services.'
    ],
    docs: [
      {
        id: 1,
        author: ['М.А. Ярошинский', 'M. Yaroshinsky'],
        post: ['Генеральный директор', 'General Director'],
        link: 'https://taxcompliance.su/Farm%20Sintez_Reference%20Letter.pdf'
      }
    ],
    logo: require('./assets/farm_sintez.png')
  }
]
