export type SectionT = {
  id: string
  el: JSX.Element
  title: string[]
  tileRef?: React.RefObject<HTMLDivElement>
}

export const sections = [
  { id: 'aboutUs', title: ['О нас', 'About Us'] },
  { id: 'services', title: ['Услуги', 'Services'] },
  { id: 'clients', title: ['Клиенты', 'Clients'] },
  { id: 'contacts', title: ['Контакты', 'Contacts'] }
]
