import React, { PropsWithChildren } from 'react'
import { css } from 'styled-components'

import { breakpoints, useDimensions } from 'app/shared'
import { styled, theme } from 'ui/styles'

import { ReactComponent as CloseSvg } from 'ui/icons/close.svg'
import { ReactComponent as SmallCloseSvg } from 'ui/icons/close_small.svg'

const Wrapper = styled.div<{ isOutline: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  width: 56px;
  height: 56px;

  border-radius: 50%;
  background-color: ${({ isOutline }) => (isOutline ? 'white' : `${theme.palette.blue}`)};
  transition: background-color ${theme.transition.hover}ms ease;

  ${({ isOutline }) =>
    isOutline &&
    css`
      border: 1px solid ${theme.palette.coldGrey};

      path {
        stroke: ${theme.palette.primary};
      }

      @media (max-width: ${breakpoints.mobile - 1}px) {
        border: unset;
      }
    `}

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.blue};
      border: 1px solid ${theme.palette.blue};

      path {
        stroke: white;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 24px;
    min-width: 24px;
    height: 24px;

    path {
      stroke: unset;
    }

    @media (hover: hover) {
      &:hover {
        background-color: unset;

        path {
          fill: ${theme.palette.blue};
        }
      }
    }
  }
`

type Props = {
  isOutline?: boolean
  onClick?: () => void
}

export const RoundedButton: React.FC<PropsWithChildren<Props>> = ({ isOutline = false, onClick = () => {} }) => {
  const { isMobile } = useDimensions()

  return (
    <Wrapper onClick={onClick} isOutline={isOutline}>
      {isMobile ? <SmallCloseSvg /> : <CloseSvg />}
    </Wrapper>
  )
}
