import { useMemo, useState } from 'react'

export const usePagination = <T extends unknown>(array: T[], pageSize: number = 4) => {
  const [activePage, onChange] = useState(0)

  return useMemo(() => {
    return {
      pageCount: Math.round(array.length / pageSize),
      activePage,
      onChange,
      items: array.slice(activePage * pageSize, activePage * pageSize + pageSize)
    }
  }, [pageSize, array, activePage, onChange])
}
