import { ModalTemplate } from 'app/features/modals/templates'
import { FC } from 'react'
import { Client } from '../../data/clients'
import { Pagination, usePagination } from '../../ui'
import { DocItem } from '../DocItem/DocItem'
import { styled } from 'ui/styles'
import { CloseButton } from './CloseButton'

const Wrapper = styled.div`
  margin-bottom: 12px;
  width: 548px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

type Props = {
  client: Client
  onClose?: () => void
}

export const DocsModal: FC<Props> = ({ onClose = () => {}, client }) => {
  const { items, ...pagination } = usePagination(client.docs, 6)

  return (
    <>
      <CloseButton onClick={onClose} />
      <ModalTemplate>
        <Wrapper>
          {items.map((doc) => {
            return <DocItem key={doc.id} doc={doc} logo={client.logo} />
          })}
        </Wrapper>
        <Pagination {...pagination} />
      </ModalTemplate>
    </>
  )
}
