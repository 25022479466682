import { FC, PropsWithChildren } from 'react'
import { styled } from 'ui/styles'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -82px;

  @media (max-width: 768px) {
    top: -44px;
  }
`

export const PatternWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <div style={{ width: '100vw' }}>{children}</div>
    </Wrapper>
  )
}
