export const theme = {
  palette: {
    white: '#ffffff',
    red: '#FF0000',
    blue: '#466BBC',
    green: '#1BD741',
    primary: '#081024',
    coldGrey: '#A3AFC6',
    deepBlue: '#141F3E',
    lightBlue: '#91B3FA',
    middleGrey: '#9D9D9D',
    brightBlue: '#5280FF',
    bluePastel: '#EDF0F4'
  },
  fontFamily: 'PT Root UI',
  transition: {
    hover: 200,
    slide: 300,
    blink: 500,
    spin: 700,
    float: 5000
  },
  typography: {
    // H0 - H10
    h0: {
      fontSize: '64px',
      lineHeight: '54px',
      fontWeight: '700'
    },
    h1: {
      fontSize: '42px',
      lineHeight: '54px',
      fontWeight: '700'
    },
    h2: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: '700'
    },
    h3: {
      fontSize: '36px',
      lineHeight: '56px',
      fontWeight: '400'
    },
    h4: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: '700'
    },
    h5: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: '400'
    },
    h6: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '700'
    },
    h7: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: '400'
    },
    h8: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '700'
    },
    h9: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
      textTransform: 'uppercase'
    },
    h10: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    // T0 - T8
    t0: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '500'
    },
    t1: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: '400'
    },
    t2: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '500'
    },
    t3: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    t4: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '400'
    },
    t5: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    t6: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '400'
    },
    t7: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: '400'
    },
    t8: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '300'
    }
  }
}

export type Theme = typeof theme
