import { styled } from 'ui/styles'
import { ClientCard } from '../ClientCard'
import { Client } from '../../data/clients'
import { FC, useCallback, useState } from 'react'
import styles from './styles.module.scss'
import { DocsModal } from '../DocsModal'
import { Modal } from 'ui/components'
import { useDimensions } from 'app/shared'

const Container = styled.div`
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  margin: 36px 0px;

  @media (max-width: 768px) {
    gap: 24px;
    margin: 24px 0px;
  }
`

interface ListProps {
  items: Client[]
}

export const List: FC<ListProps> = ({ items }) => {
  const [modalOpened, setModalState] = useState(false)
  const [client, setClient] = useState<Client | undefined>(undefined)

  const onClose = useCallback(() => setModalState(false), [])

  const { isMobile } = useDimensions()

  return (
    <Container className={styles.List}>
      {items.map((item) => {
        return (
          <ClientCard
            onClickDoc={() => {
              setClient(item)
              setModalState(true)
            }}
            className={styles.Card}
            key={item.id}
            item={item}
          />
        )
      })}
      <Modal
        centerContent={!isMobile}
        backroundTheme="light"
        contentMaxWidth="768px"
        isVisible={modalOpened}
        onOverlayClick={onClose}
      >
        {client && <DocsModal client={client} onClose={onClose} />}
      </Modal>
    </Container>
  )
}
