import { createReducer, ActionType } from 'typesafe-actions'

import * as modal from './actions'

export type ModalAction = ActionType<typeof modal>

export type ModalType = {
  type: 'application' | 'status'
  status?: ModalStatus
}
export type ModalStatus = {
  isError: boolean
  message: string
}

export type ModalState = {
  modalIsOpen: boolean
  modalType?: ModalType
}

const initialState: ModalState = {
  modalIsOpen: false,
  modalType: {
    type: 'application',
    status: {
      isError: false,
      message: ''
    }
  }
}

export const modalReducer = createReducer<ModalState>(initialState).handleAction(
  modal.setModalOpen,
  (state: ModalState, { payload }: any) => {
    const modalType = payload.modalType

    return {
      ...state,
      modalIsOpen: payload.modalIsOpen,
      modalType: {
        type: modalType?.type,
        status: {
          isError: modalType?.status?.isError,
          message: modalType?.status?.message ? modalType?.status?.message : 'Заявка успешно создана!'
        }
      }
    }
  }
)
