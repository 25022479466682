import { styled } from 'ui/styles'

export const Container = styled.div`
  position: relative;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid ${(props) => props.theme.palette.bluePastel};
  padding: 0px 32px 24px;
  color: ${(props) => props.theme.palette.deepBlue};
  height: 360px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 521px;
    padding: unset;
    border-radius: 16px;
  }
`
