import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { setModalOpen } from 'store'

import { AppBar, ApplicationModal, Footer } from 'app/features'
import { StatusModal } from 'app/features/modals/StatusModal'
import { breakpoints } from 'app/shared'
import { styled, theme } from 'ui/styles'
import { Layout, Modal } from 'ui/components'

import sprite from 'ui/icons/sprite.svg'
import { AboutUs } from 'app/features/sections/AboutUs'
import { Services } from 'app/features/sections/Services'
import { Clients } from 'app/widgets'

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
`

const BlueBox = styled.div`
  position: absolute;
  background-color: ${theme.palette.deepBlue};
  width: 100%;
  height: 670px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: 612px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 375px;
  }
`

const StyledSprite = styled.div`
  height: 100%;
  background-repeat: repeat-x;
  background-position: bottom;
  background-image: url(${sprite});
  opacity: 0.1;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    background-size: auto 90%;
  }
`

export const App: FC = () => {
  const modalType = useSelector((state: RootState) => state.modal.modalType)
  const modalIsOpen = useSelector((state: RootState) => state.modal.modalIsOpen)
  const dispatch = useDispatch()

  const onOverlayClick = () => {
    dispatch(setModalOpen({ modalIsOpen: false }))
  }

  return (
    <Wrapper>
      <AppBar />
      <BlueBox>
        <StyledSprite />
      </BlueBox>
      <Layout>
        <section id="aboutUs">
          <AboutUs />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="clients">
          <Clients />
        </section>
      </Layout>
      <Footer />
      <Modal contentMaxWidth="768px" isVisible={modalIsOpen} onOverlayClick={onOverlayClick}>
        {modalType?.type === 'status' ? (
          <StatusModal />
        ) : (
          <ApplicationModal onClose={onOverlayClick}></ApplicationModal>
        )}
      </Modal>
    </Wrapper>
  )
}
