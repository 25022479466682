import { regExps } from 'app/shared/lib/validation'

export enum InputEnum {
  text = "text",
  tel = 'tel',
  email = 'email',
  number = 'number'
}

export enum ApplicationE {
  firstName = 'firstName',
  email = 'email',
  phone = 'phone',
  description = 'description',
}

export type ApplicationFormFieldsT = {
  firstName: string
  email: string
  phone: string
  description: string
}

export type ApplicationFormDataT = {
  field: ApplicationE
  name: string[]
  placeholder?: string[]
  require?: boolean
  type: InputEnum
  pattern?: RegExp
  isBig?: boolean
}

export const applicationForm: ApplicationFormDataT[] = [
  {
    field: ApplicationE.firstName,
    name: ['Имя', 'First name'],
    placeholder: ['Константин', 'Konstantin'],
    require: true,
    type: InputEnum.text,
    pattern: regExps.initials
  },
  {
    field: ApplicationE.phone,
    name: ['Телефон', 'Phone'],
    require: true,
    type: InputEnum.tel,
    pattern: regExps.phone
  },
  {
    field: ApplicationE.email,
    name: ['Email', 'Email'],
    placeholder: ['kostikkostochka@mail.ru', 'kostikkostochka@mail.ru'],
    type: InputEnum.email,
    pattern: regExps.email
  },
  {
    field: ApplicationE.description,
    name: ['Опишите задание', 'Describe the assignment'],
    isBig: true,
    type: InputEnum.text,
  },
]