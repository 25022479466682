import { FC } from 'react'
import { Doc } from '../../data/clients'
import { styled } from 'ui/styles'
import { useLanguage } from 'app/shared'
import { Name, Post, FileLink } from '../../ui'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid ${(props) => props.theme.palette.bluePastel};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-right: 66px;
  }
`

const AuthorBlock = styled.div`
  flex: 1;
`

const MediaBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;

  @media (max-width: 768px) {
    gap: 0px;
    justify-content: space-between;
    width: 100%;
  }
`

const Logo = styled.img`
  width: 96px;
  height: 48px;
`

interface DocItemProps {
  logo: string
  doc: Doc
}

export const DocItem: FC<DocItemProps> = ({ doc, logo }) => {
  const { languageState } = useLanguage()

  return (
    <Container>
      <AuthorBlock>
        <Name>{doc.author[languageState.number]}</Name>
        <Post>{doc.post[languageState.number]}</Post>
      </AuthorBlock>
      <MediaBlock>
        <FileLink href={doc.link} />
        <Logo src={logo} />
      </MediaBlock>
    </Container>
  )
}
