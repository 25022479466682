import { FC, useEffect, useState } from 'react'

import { breakpoints, useDimensions, useScroll } from 'app/shared'
import { Image, Layout } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { AppBarItems, LanguageBar } from '../molecules'
import { BurgerIcon } from '../atoms'
import { Links } from '../molecules/Links'

const Wrapper = styled.nav`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 84px;
  align-items: center;
  background-color: ${theme.palette.deepBlue};
  z-index: 50;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 64px;
  }
`

const StyledLayout = styled(Layout)`
  align-items: center;
`

const BlurBackground = styled.div<{ isOpen: boolean }>`
  display: flex;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity ${theme.transition.hover}ms ease;
  z-index: 5;

  backdrop-filter: blur(12px);
  -web-kit-backdrop-filter: blur(12px);
`

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled(Image)`
  width: 32px;
  height: 30px;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AppBar: FC = () => {
  const [burgerIsOpened, setBurgerIsOpened] = useState(false)
  const body = document.body

  const { isMobile } = useDimensions()
  const { goTo } = useScroll()

  useEffect(() => {
    if (burgerIsOpened) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [body.classList, burgerIsOpened])

  const handleBurgerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setBurgerIsOpened(!burgerIsOpened)
  }

  const handleItemClick = (i: number) => {
    goTo(i)
    setBurgerIsOpened(false)
  }

  return (
    <>
      {isMobile && <BlurBackground isOpen={burgerIsOpened} onClick={() => setBurgerIsOpened(false)} />}
      <Wrapper>
        <StyledLayout>
          {isMobile ? (
            <MobileWrapper>
              <Logo name="logo_light" isIcon />
              <ActionsWrapper>
                <Links />
                <LanguageBar />
                <BurgerIcon onClick={handleBurgerClick} opened={burgerIsOpened} />
              </ActionsWrapper>
            </MobileWrapper>
          ) : null}
          <AppBarItems burgerIsOpened={burgerIsOpened} handleItemClick={handleItemClick} />
        </StyledLayout>
      </Wrapper>
    </>
  )
}
