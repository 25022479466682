import { breakpoints } from 'app/shared'
import React, { PropsWithChildren } from 'react'
import { css } from 'styled-components'

import { getStyles, styled, theme } from 'ui/styles'

import { Button, ButtonProps } from './Button'

const Wrapper = styled(Button)<{ isDisabled: boolean; isBig: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 214px;
  height: fit-content;
  min-height: 48px;

  border: 2px solid ${theme.palette.blue};
  border-radius: 32px;
  color: ${theme.palette.blue};
  background-color: white;

  transition: background-color, color, ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.blue};
      color: white;
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      border-color: ${theme.palette.coldGrey};
      color: ${theme.palette.coldGrey};
    `}

  ${({ isBig }) =>
    isBig &&
    css`
      ${getStyles('h9')}
      max-width: 270px;
      min-height: 60px;
      text-transform: uppercase;

      @media (max-width: ${breakpoints.mobile - 1}px) {
        min-height: 52px;
      }
    `}
`

type Props = {
  isDisabled?: boolean
  isBig?: boolean
  className?: string
  type?: 'button' | 'submit'
} & ButtonProps

export const OutlineButton: React.FC<PropsWithChildren<Props>> = ({
  className,
  isDisabled = false,
  isBig = false,
  type = 'button',
  onClick,
  children
}) => (
  <Wrapper className={className} type={type} isDisabled={isDisabled} isBig={isBig} onClick={onClick}>
    {children}
  </Wrapper>
)
