import { FC } from 'react'

import { breakpoints, useDimensions } from 'app/shared'
import { Text } from 'ui/components'
import { styled } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`

const Title = styled(Text)`
  margin-bottom: 8px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 88px;
  margin-top: 8px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: auto;
  }
`

export type ContactPropsT = {
  title: string
  contact: string[]
}

export const Contact: FC<ContactPropsT> = ({ title, contact }) => {
  const { isMobile } = useDimensions()

  return (
    <Wrapper>
      <Title variant="t0">{title}</Title>
      <Content>
        {contact.map((el) => (
          <Text key={el} variant={isMobile ? 't7' : 't3'}>
            {el}
          </Text>
        ))}
      </Content>
    </Wrapper>
  )
}
