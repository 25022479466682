import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'

import { ApplicationE, ApplicationFormDataT, ApplicationFormFieldsT, InputEnum } from 'data/application'
import { breakpoints, useLanguage } from 'app/shared'
import { styled } from 'ui/styles'

import { Text } from '../Text'
import { PhoneField } from './PhoneInput'
import { Image } from '../Image'
import { Button } from '../Button'
import { Textarea } from './Textarea'

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const Label = styled(Text)`
  display: flex;
  max-width: 300px;
  width: 100%;
  margin-top: 8px;
`

const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ClearIcon = styled(Image)<{ right?: number }>`
  position: absolute;
  right: ${({ right }) => right}px;
  top: 8px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.6);
    }
  }
`

type Props = {
  input: ApplicationFormDataT
  control: Control<ApplicationFormFieldsT, object>
  clearField: () => void
  isValid: boolean
}

const ERROR_TEXT = ['Поле заполнено неверно', 'The field is filled in incorrectly']

export const TextInput: FC<Props> = ({ input, control, isValid }) => {
  const isPhone = input.type === InputEnum.tel
  const { languageState } = useLanguage()

  return (
    <Wrapper>
      <Label variant="t5" color="black" inline>
        {input.name[languageState.number]}
        {input.require ? '*' : ''}
      </Label>
      <Controller
        control={control}
        name={input.field as ApplicationE}
        rules={{
          required: input.require,
          pattern: input.pattern,
          validate: (value: string) => (input.require ? !!value.trim() : true)
        }}
        render={({ field }) => (
          <TextAreaWrapper>
            {isPhone ? (
              <PhoneField value={field.value} isValid={isValid} field={field} />
            ) : (
              <>
                <Textarea
                  value={field.value}
                  isValid={isValid}
                  isBig={input.isBig}
                  placeholder={input.placeholder ? input.placeholder[languageState.number] : ''}
                  field={field}
                />
                {isValid ? null : (
                  <Text variant="t8" color="red">
                    {ERROR_TEXT[languageState.number]}
                  </Text>
                )}
              </>
            )}
            {Boolean(field.value) && (
              <Button onClick={() => field.onChange('')}>
                <ClearIcon name="clear_button" right={isPhone ? 60 : 16} alt="clear" isIcon />
              </Button>
            )}
          </TextAreaWrapper>
        )}
      />
    </Wrapper>
  )
}
