import { styled } from 'ui/styles'

export const Content = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.bluePastel};
  display: flex;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    border-bottom: 0px;
  }
`
