import { FC, useCallback } from 'react'

import { Layout, Text, Column } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { Contact, ContactPropsT } from './Contact'

import { ReactComponent as LogoIcon } from 'ui/icons/logo_dark.svg'
import { ReactComponent as WhatsappIcon } from 'ui/icons/whatsapp1.svg'
import { ReactComponent as TelegramIcon } from 'ui/icons/telegramIcon1.svg'
import { ReactComponent as LinkedinIcon } from 'ui/icons/linkedinIcon1.svg'
import { breakpoints, useLanguage } from 'app/shared'

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.bluePastel};
  padding-top: 74px;
  padding-bottom: 90px;
`

const SloganWrapper = styled.div``

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-top: 32px;
  }
`

const StyledLayout = styled(Layout)`
  display: flex;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const Logo = styled(LogoIcon)`
  width: 62px;
  margin-right: 39px;
  float: left;
`

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  box-sizing: border-box;
  padding: 0px 24px;
  background: ${theme.palette.white};
  border-radius: 12px;
  border: 1px solid #141f3e;
  margin-left: 16px;
  transition: all ${theme.transition.hover}ms ease;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (hover: hover) {
    &:hover {
      background: ${theme.palette.deepBlue};
      color: white;
    }
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-left: 0px;
    width: 100%;
  }
`

const ButtonText = styled(Text)`
  width: 201px;
`

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

const CopyrightText = styled(Text)`
  margin-top: 20px;
`

const contacts: ContactPropsT[][] = [
  [
    {
      title: 'Офис в Дубае',
      contact: [
        'DMCC Business Centre Level No 1 Jewellery & Gemplex 3 Dubai United Arab Emirates',
        '+971 54 596 75 29',
        'info@tc-uae.com'
      ]
    },
    {
      title: 'Офис в Москве',
      contact: ['Москва, Пресненская Набережная д. 10, стр. 2', '+7 495 121 04 44', 'info@tc-uae.com']
    }
  ],
  [
    {
      title: 'Dubai Office',
      contact: [
        'DMCC Business Centre Level No 1 Jewellery & Gemplex 3 Dubai United Arab Emirates',
        '+971 54 596 75 29',
        'info@tc-uae.com'
      ]
    },
    {
      title: 'Moscow Office',
      contact: ['Moscow, Presnenskaya Embankment 10, bldg. 2', '+7 495 121 04 44', 'info@tc-uae.com']
    }
  ]
]

const SLOGAN = [
  'Мы помогаем клиентам чувствовать себя уверенно, поддерживая их на вершине новых изменений в потоке времени',
  'We help our clients feel confident by keeping them on top of changes in the flow of time'
]

const WHATS_APP = ['Написать в WhatsApp', 'Message on WhatsApp']
const TELEGRAM = ['Подписаться в Telegram', 'Subscribe to Telegram']
const LINKEDIN = ['Отслеживать в LinkedIn', 'Follow on LinkedIn']

const TELEGRAM_LINK = 'https://t.me/tc_UAE'
const LINKEDIN_LINK = 'https://www.linkedin.com/company/tc-uae/about/'
const WHATSUP_LINK = 'https://api.whatsapp.com/send/?phone=79099055007'

export const Footer: FC = () => {
  const { languageState } = useLanguage()

  const onLinkClick = useCallback((link: string) => {
    window.open(link, '_blank')
  }, [])

  return (
    <Wrapper id='contacts'>
      <StyledLayout>
        <Column>
          <SloganWrapper>
            <Logo />
            <Text variant="h6" inline>
              {SLOGAN[languageState.number]}
            </Text>
          </SloganWrapper>
          {contacts[languageState.number].map((el, i) => (
            <Contact key={i} {...el} />
          ))}
          <CopyrightText variant="t7" color={theme.palette.coldGrey}>
            © taxcompliance 2023
          </CopyrightText>
        </Column>
        <StyledContainer>
          <ContactButton onClick={() => onLinkClick(LINKEDIN_LINK)}>
            <IconWrapper>
              <LinkedinIcon />
            </IconWrapper>
            <ButtonText variant="t4" align="left">
              {LINKEDIN[languageState.number]}
            </ButtonText>
          </ContactButton>
          <ContactButton onClick={() => onLinkClick(TELEGRAM_LINK)}>
            <IconWrapper>
              <TelegramIcon />
            </IconWrapper>
            <ButtonText variant="t4" align="left">
              {TELEGRAM[languageState.number]}
            </ButtonText>
          </ContactButton>
          <ContactButton onClick={() => onLinkClick(WHATSUP_LINK)}>
            <IconWrapper>
              <WhatsappIcon />
            </IconWrapper>
            <ButtonText variant="t4" align="left">
              {WHATS_APP[languageState.number]}
            </ButtonText>
          </ContactButton>
        </StyledContainer>
      </StyledLayout>
    </Wrapper>
  )
}
