import { createContext, Dispatch, FC, PropsWithChildren, useContext, useReducer } from 'react'

type TLanguageState = {
  shortName: string
  displayName: string
  number: number
}

export const LANGUAGES: TLanguageState[] = [
  { shortName: 'Rus', displayName: 'Rus (Russian)', number: 0 },
  { shortName: 'En', displayName: 'En (English)', number: 1 }
]

type TLanguageContext = {
  languageState: TLanguageState
  dispatchLanguage: Dispatch<number>
}

export const LanguageContext = createContext<TLanguageContext>({} as TLanguageContext)
export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider: FC<PropsWithChildren> = ({ children }) => {
  function languageReducer(state: TLanguageState, action: number) {
    switch (action) {
      case 0:
        return LANGUAGES[0]
      case 1:
        return LANGUAGES[1]
      default:
        return LANGUAGES[0]
    }
  }

  const [state, dispatchLanguage] = useReducer(languageReducer, LANGUAGES[0])

  return (
    <LanguageContext.Provider value={{ languageState: state, dispatchLanguage: dispatchLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
