import { FC, useState } from 'react'

import { LANGUAGES, useLanguage } from 'app/shared'
import { Text, Image } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Panel = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 4px;
  margin-left: 24px;
  cursor: pointer;

  &.opened {
    background-color: ${theme.palette.blue};
    border-radius: 6px;
  }
`

const PanelItems = styled.div`
  position: absolute;
  bottom: -80px;
  right: 0px;
  background-color: white;
  border-radius: 8px;
`

const PanelItem = styled.button`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  min-width: 146px;
`

const StyledArrow = styled(Image)`
  margin-left: 8px;

  &.opened {
    transform: rotate(180deg);
  }
`

export const LanguageBar: FC = () => {
  const [panelIsOpened, setPanelIsOpened] = useState(false)

  const { dispatchLanguage, languageState } = useLanguage()
  const onToggleLanguageBar = () => {
    setPanelIsOpened((prev) => !prev)
  }

  return (
    <Panel className={panelIsOpened ? 'opened' : undefined} onClick={onToggleLanguageBar}>
      <Text variant="t5" color="white">
        {languageState.shortName}
      </Text>
      <StyledArrow name="arrow_down" className={panelIsOpened ? 'opened' : undefined} isIcon />
      {panelIsOpened ? (
        <PanelItems>
          {LANGUAGES.map((el, i) => (
            <PanelItem key={el.shortName} onClick={() => dispatchLanguage(i)}>
              <Text variant="t5" color={theme.palette.blue}>
                {el.displayName}
              </Text>
            </PanelItem>
          ))}
        </PanelItems>
      ) : null}
    </Panel>
  )
}
