import React from 'react'

import { breakpoints, useDimensions } from 'app/shared'
import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
  height: 100%;
  color: white;
  transition: color ${theme.transition.hover}ms ease;
  padding: 0px 6px;

  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &::after {
    content: '';
    position: relative;
    width: 0%;
    height: 1px;
    background-color: white;

    transition: background-color, width, ${theme.transition.hover}ms ease;
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.palette.blue};

      &::after {
        width: calc(100% + 12px);
        background-color: ${theme.palette.blue};
        margin: 0 -6px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    color: initial;
    margin-bottom: 24px;

    &::after {
      content: '';
      display: none;
    }
  }
`

const StyledText = styled(Text)``

type Props = {
  title: string
  onClick: (ev: React.MouseEvent) => void
}

export const AppBarItem: React.FC<Props> = ({ title, onClick }) => {
  const { isMobile } = useDimensions()

  return (
    <Wrapper onClick={(ev) => onClick(ev)}>
      <StyledText variant={isMobile ? 't1' : 't4'}>{title}</StyledText>
    </Wrapper>
  )
}
