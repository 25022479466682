import { FC, memo } from 'react'

import { styled } from 'ui/styles'
import { breakpoints } from 'app/shared'

import { ReactComponent as TelegramIcon } from 'ui/icons/telegramIcon.svg'
import { ReactComponent as LinkedinIcon } from 'ui/icons/linkedinIcon.svg'
import { ReactComponent as WhatsappIcon } from 'ui/icons/whatsappIcon.svg'
import { Link } from 'ui/components'

const LinksWrapper = styled.div`
  display: flex;
  margin-right: 40px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-right: unset;
  }
`

const TelegramLink = styled(TelegramIcon)`
  width: 44px;
  height: 44px;
  margin-left: 24px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 320px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  height: 44px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 30px;
  }
`

const LinkedinLink = styled(LinkedinIcon)`
  width: 44px;
  height: 44px;
  margin-left: 24px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 30px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: 320px) {
    display: none;
  }
`

const WhatsAppLink = styled(WhatsappIcon)`
  width: 44px;
  height: 44px;
  margin-left: 24px;

  @media (max-width: 860px) {
    display: none;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    display: unset;
    width: 30px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: 320px) {
    display: none;
  }
`
const TELEGRAM_LINK = 'https://t.me/UAE_TC'
const LINKEDIN_LINK = 'https://www.linkedin.com/company/tc-uae/about/'
const WHATSUP_LINK = 'https://api.whatsapp.com/send/?phone=971547524006'

const _Links: FC = () => {
  return (
    <LinksWrapper>
      <StyledLink href={TELEGRAM_LINK} target="_blank">
        <TelegramLink />
      </StyledLink>
      <StyledLink href={WHATSUP_LINK} target="_blank">
        <WhatsAppLink />
      </StyledLink>
      <StyledLink href={LINKEDIN_LINK} target="_blank">
        <LinkedinLink />
      </StyledLink>
    </LinksWrapper>
  )
}

export const Links = memo(_Links)
