import { FC, memo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { applicationForm, ApplicationFormFieldsT } from 'data/application'
import { Divider, Loader, OutlineButton, Text, TextInput } from 'ui/components'
import { breakpoints, useDimensions, useLanguage } from 'app/shared'
import { styled, theme } from 'ui/styles'

import { ModalTemplate } from './templates'
import { setModalOpen } from 'store'
import { useDispatch } from 'react-redux'
import emailjs from 'emailjs-com';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const FieldWrapper = styled.section`
  display: flex;
`

const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`
const SubmitButton = styled(OutlineButton)`
  max-width: 215px;
  @media (max-width: ${breakpoints.mobile - 1}px) {
    max-width: 100%;
    margin-bottom: 16px;
  }
`

const PPText = styled(Text)`
  width: 285px;
`

const StyledLink = styled.a`
  color: ${theme.palette.blue};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`

const SUBMIT_LABEL = ['Отправить', 'Send']

const APPLICATION_HEADER = ['Связаться с нами', 'Contact Us']

const TERMS_CLICK = ['Нажимая на кнопку, вы соглашаетесь с ', 'By clicking the button, you agree to the ']

const TERMS = ['Обработкой персональных данных', 'Processing of Personal Data']

type Props = {
  onClose?: () => void
}

const ApplicationHeader = memo(({ isMobile, languageNum }: { isMobile: boolean; languageNum: number }) => (
  <Text variant={isMobile ? 't0' : 'h3'}>{APPLICATION_HEADER[languageNum]}</Text>
))

const PRIVACY_POLICY_LINK = 'https://storage.yandexcloud.net/codeitup-storage/just_test_politic_file.pdf'

export const ApplicationModal: FC<Props> = ({ onClose = () => {} }) => {
  const { isMobile } = useDimensions()
  const { languageState } = useLanguage()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid, errors },
    reset,
    resetField,
    handleSubmit
  } = useForm<ApplicationFormFieldsT>({ mode: 'onBlur' })

  const onSubmit = async (fields: ApplicationFormFieldsT) => {
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    console.log(serviceID);

    if (!serviceID || !templateID || !userID){
      reset()
      dispatch(
        setModalOpen({
          modalIsOpen: true,
          modalType: { type: 'status', status: { isError: true, message: `Ошибка` } }
        })
      )
      return;
    }

    setIsLoading(true);

    emailjs.send(serviceID, templateID, fields, userID)
    .then((response) => {
      reset()
      dispatch(
        setModalOpen({
          modalIsOpen: true,
          modalType: {
            type: 'status',
            status: { isError: false, message: 'Успешно!' }
          }
        })
      )
    })
    .catch((err) => {
      reset()
      dispatch(
        setModalOpen({
          modalIsOpen: true,
          modalType: { type: 'status', status: { isError: true, message: `${err.message}` } }
        })
      )
    });
  }

  return (
    <ModalTemplate
      header={<ApplicationHeader isMobile={isMobile} languageNum={languageState.number} />}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {applicationForm.map((el) => (
          <FieldWrapper key={el.field}>
            <TextInput
              control={control}
              input={el}
              clearField={() => resetField(el.field)}
              isValid={!Boolean(errors[el.field])}
            />
          </FieldWrapper>
        ))}
        <SubmitWrapper>
          <Divider color={theme.palette.blue} withMargin />
          <FormFooter>
            <SubmitButton type="submit" isDisabled={!isValid || isLoading}>
              {isLoading ? <Loader /> : <Text variant="t0">{SUBMIT_LABEL[languageState.number]}</Text>}
            </SubmitButton>
            <PPText variant="t7" inline>
              {TERMS_CLICK[languageState.number]}
              <StyledLink href={PRIVACY_POLICY_LINK} target="_blank">
                <Text variant="t7" inline>
                  {TERMS[languageState.number]}
                </Text>
              </StyledLink>
            </PPText>
          </FormFooter>
        </SubmitWrapper>
      </Form>
    </ModalTemplate>
  )
}
