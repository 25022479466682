import React, { PropsWithChildren } from 'react'
import { css } from 'styled-components'

import { styled, theme } from 'ui/styles'

const Wrapper = styled.button<{ isError: boolean; isDisabled: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 202px;
  height: fit-content;
  min-height: 48px;

  padding: 9px 23px;
  border-radius: 16px;
  color: ${({ isError, isDisabled }) => (isError || isDisabled ? `${theme.palette.coldGrey}` : 'white')};
  background-color: ${({ isError, isDisabled }) => (isError || isDisabled ? 'black' : `${theme.palette.blue}`)};
  border: 1px solid ${theme.palette.blue};

  transition: background-color ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.white};

      * {
        color: ${theme.palette.blue};
      }
    }
  }

  ${({ isError, isDisabled }) =>
    (isError || isDisabled) &&
    css`
      border: 1px solid ${isError ? `${theme.palette.red}` : `${theme.palette.coldGrey}`};
      pointer-events: none;
    `}
`

type Props = {
  isError?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

export const PrimaryButton: React.FC<PropsWithChildren<Props>> = ({
  isError = false,
  isDisabled = false,
  onClick,
  children
}) => (
  <Wrapper isError={isError} isDisabled={isDisabled} onClick={onClick}>
    {children}
  </Wrapper>
)
