import { styled } from 'ui/styles'

export const InfoBlock = styled.div`
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid ${(props) => props.theme.palette.bluePastel};

  @media (max-width: 768px) {
    border-right: none;
    padding: 0px 0px 12px;
    margin: 0px 16px;
    border-bottom: 1px solid ${(props) => props.theme.palette.bluePastel};
  }
`
export const Description = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-weight: ${(props) => props.theme.typography.t5.fontWeight};
  line-height: ${(props) => props.theme.typography.t5.lineHeight};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    -webkit-line-clamp: 9;
  }
`
export const Title = styled.h6`
  font-size: ${(props) => props.theme.typography.h7.fontSize};
  font-weight: 700;
  line-height: ${(props) => props.theme.typography.h7.lineHeight};
`
