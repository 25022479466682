import { FC } from 'react'
import { sections } from 'app/features'
import { useLanguage } from 'app/shared'

import { clients } from './data/clients'
import { List } from './components'
import { Pagination, usePagination, Header, Wrapper, Pattern } from './ui'

import { PatternWrapper } from './ui/PatternWrapper'

export const Clients: FC = () => {
  const { languageState } = useLanguage()
  const title = sections[2].title[languageState.number] || ''

  const { items, ...pagination } = usePagination(clients)

  return (
    <Wrapper>
      <PatternWrapper>
        <Pattern type="blue" />
      </PatternWrapper>
      <Header title={title} />
      <List items={items} />
      <Pagination {...pagination} />
    </Wrapper>
  )
}
