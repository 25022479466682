import React from 'react'

import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  width: 22px;
  height: 100%;
  align-items: center;
  cursor: pointer;

  margin-left: 8px;
`

const Span = styled.span`
  height: 1px;
  width: 100%;
  position: absolute;
  background: white;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: ${theme.transition.hover}ms ease;
  -moz-transition: ${theme.transition.hover}ms ease;
  -o-transition: ${theme.transition.hover}ms ease;
  transition: ${theme.transition.hover}ms ease;

  &:nth-child(1) {
    top: 8px;
  }
  &:nth-child(2),
  &:nth-child(3) {
    top: 14px;
  }
  &:nth-child(4) {
    top: 20px;
  }
`

const Icon = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 30px;

  &.opened {
    background-color: ${theme.palette.blue};

    span {
      background-color: white;
    }
  }

  &.opened ${Span}:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  &.opened ${Span}:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.opened ${Span}:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &.opened ${Span}:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`
type Props = {
  opened: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const BurgerIcon: React.FC<Props> = ({ onClick, opened }) => (
  <Wrapper>
    <Icon onClick={(e) => onClick(e)} className={opened ? 'opened' : ''}>
      <Span />
      <Span />
      <Span />
      <Span />
    </Icon>
  </Wrapper>
)
