import { FC, useState } from 'react'
import { css } from 'styled-components'

import { BoxShadow, Column, Image, Text } from 'ui/components'
import { getStyles, styled, theme } from 'ui/styles'
import { breakpoints, useDimensions, useLanguage } from 'app/shared'

import { ReactComponent as ActionIcon } from 'ui/icons/arrow_diag.svg'
import { DESCRIPRION, INCLUDE, SERVICES_DATA, TITLE } from './translation'

const Wrapper = styled.div`
  padding: 132px 0px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 102px 0px;
  }
`

const Box = styled(BoxShadow)`
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  height: 766px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    height: 1000px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 1573px;
  }
`

const LeftSide = styled(Column)`
  width: 100%;
  max-width: 428px;
  padding: 64px;
  background-color: ${theme.palette.deepBlue};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 54px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    padding: 32px;
    align-items: center;
  }
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 916px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: 100%;
    height: 100%;
  }
`

const LeftTitle = styled(Text)`
  margin-top: 80%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-top: 0px;
  }
`

const LeftDescr = styled(Text)`
  margin-top: 54px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: 300px;
    margin-top: 18px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('t5')};
    max-width: 215px;
  }
`

const Panel = styled.div<{ hovered: boolean; opened: boolean }>`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 54px 90px;
  color: ${theme.palette.primary};
  transition: background-color ${theme.transition.hover}ms ease;

  ${({ hovered, opened }) =>
    hovered && !opened
      ? css`
          background-color: ${theme.palette.bluePastel};
        `
      : opened
      ? css`
          cursor: auto;
        `
      : null}

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 54px 71px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 36px;
  }
`

const PanelActionButton = styled.button`
  position: absolute;
  right: 54px;
  bottom: 54px;
`

const StyledActionIcon = styled(ActionIcon)<{ $active: boolean; hovered: boolean }>`
  transition: opacity ${theme.transition.hover}ms ease;
  opacity: 0.25;

  path {
    transition: stroke, fill, ${theme.transition.hover}ms ease;
    stroke: ${theme.palette.coldGrey};
    fill: ${theme.palette.coldGrey};
  }

  ${({ hovered, $active }) =>
    hovered || $active
      ? css`
          opacity: 1;
          path {
            stroke: ${theme.palette.deepBlue};
            fill: ${theme.palette.deepBlue};
          }
        `
      : null}

  ${({ $active }) =>
    $active
      ? css`
          transform: rotate(180deg);
        `
      : null}
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column-reverse;
  }
`

const TextColumn = styled(Column)`
  justify-content: space-between;
  height: 100%;
`

const ServiceText = styled(Text)`
  max-width: 90%;
  min-width: 258px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    max-width: 100%;
    min-width: unset;
    margin-top: 20px;
  }
`

const IconWrapper = styled.div<{ hovered: boolean; opened: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  max-width: 160px;
  max-height: 148px;
  margin-left: 20px;
  margin-right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-right: 64px;
  }
`

const IncludeText = styled(Text)`
  margin-top: 28px;
`

const ListItem = styled(Text)`
  margin-top: 24px;
`

export const Services: FC = () => {
  const { isMobile } = useDimensions()
  const [openedService, setOpenedService] = useState<null | number>(null)
  const [hoveredService, setHoveredService] = useState<null | number>(null)
  const { languageState } = useLanguage()

  const onServiceOpenClick = (i: number) => {
    if (openedService === null) {
      setOpenedService(i)
    }
  }
  const onServiceCloseClick = () => {
    if (openedService !== null) {
      setOpenedService(null)
    }
  }

  return (
    <Wrapper>
      <Box>
        <LeftSide>
          <LeftTitle variant="h1" color="white" align={isMobile ? 'center' : 'start'}>
            {TITLE[languageState.number]}
          </LeftTitle>
          <LeftDescr variant="t1" color="white" align={isMobile ? 'center' : 'start'}>
            {DESCRIPRION[languageState.number]}
          </LeftDescr>
        </LeftSide>
        <RightSide>
          {SERVICES_DATA[languageState.number].map((el, i) =>
            openedService === null || openedService === i ? (
              <Panel
                key={el.title}
                onMouseLeave={() => setHoveredService(null)}
                onMouseEnter={() => setHoveredService(i)}
                onClick={() => onServiceOpenClick(i)}
                hovered={hoveredService === i}
                opened={openedService !== null}
              >
                <PanelActionButton onClick={() => onServiceCloseClick()}>
                  <StyledActionIcon $active={openedService !== null} hovered={hoveredService === i} />
                </PanelActionButton>
                <InfoWrapper>
                  <TextColumn>
                    <ServiceText variant="h6" align={isMobile ? 'center' : 'start'}>
                      {el.title}
                    </ServiceText>
                    <ServiceText variant={isMobile ? 't5' : 't3'} align={isMobile ? 'center' : 'start'}>
                      {el.descr}
                    </ServiceText>
                  </TextColumn>
                  <IconWrapper hovered={hoveredService === i} opened={openedService !== null}>
                    <Image name={`${el.icon}.png`} />
                  </IconWrapper>
                </InfoWrapper>
                {openedService === i ? (
                  <Column>
                    <IncludeText variant="t3">{INCLUDE[languageState.number]}</IncludeText>
                    <>
                      {el.services.map((el) => (
                        <ListItem key={el} variant="t3">
                          {el}
                        </ListItem>
                      ))}
                    </>
                  </Column>
                ) : null}
              </Panel>
            ) : null
          )}
        </RightSide>
      </Box>
    </Wrapper>
  )
}
