import { styled } from 'ui/styles'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 167px;

  @media (max-width: 768px) {
    margin-bottom: 144px;
  }
`
