import { breakpoints } from 'app/shared'
import { styled } from 'ui/styles'

export const Layout = styled.div`
  width: 100%;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`
