import { FC, memo } from 'react'

import { BoxShadow, Image, Text } from 'ui/components'
import { getStyles, styled, theme } from 'ui/styles'
import { breakpoints, useDimensions, useLanguage } from 'app/shared'
import { useDispatch } from 'react-redux'
import { setModalOpen } from 'store'
import { css } from 'styled-components'

const WrapperUpper = styled.div`
  position: relative;
  height: 670px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: 590px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 404px;
  }
`

const Helicopter = styled(Image)`
  position: absolute;
  left: 350px;
  top: 108px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 944px;
    height: 566px;
    left: 18px;
    top: 200px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 543px;
    height: 372px;
    right: 0;
    left: unset;
    top: 185px;
  }

  @media (max-width: 530px) {
    left: -72px;
    right: unset;
  }

  @media (max-width: 360px) {
    top: 205px;
  }
`

const Title = styled.title`
  display: flex;
  align-items: center;
  margin-top: 100px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-top: 75px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-top: 26px;
  }
`

const StyledTitle = styled(Text)`
  width: 100%;
  max-width: 899px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h4')};
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h9')};
  }
`

const Logo = styled(Image)`
  margin-right: 48px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-right: 66px;
    width: 96px;
    height: 96px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    display: none;
  }
`

const WrapperMission = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

const MissionText = styled(Text)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin-top: 88px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h5')};
    max-width: 476px;
    margin-top: 52px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h10')};
    max-width: 100%;
    margin-top: 26px;
  }
`

const Rectangle = styled.div`
  position: absolute;
  width: 182px;
  height: 359px;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: ${theme.palette.lightBlue};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 130px;
    height: 257px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 119px;
    height: 280px;
  }
  @media (max-width: 530px) {
    top: 70px;
    height: 230px;
  }
  @media (max-width: 360px) {
    top: 110px;
  }
`

const WrapperLower = styled.div`
  position: relative;
`

const ServicesTitle = styled(Text)`
  padding-top: 102px;
  padding-left: 10px;
  height: 310px;
  max-width: 746px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h6')};
    max-width: 391px;
    padding-top: 78px;
    height: 250px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h7')};
    max-width: 100%;
    font-weight: 700;
    padding-top: 122px;
    height: 255px;
  }
  @media (max-width: 530px) {
    height: 285px;
  }
  @media (max-width: 360px) {
    height: 305px;
    padding-top: 142px;
  }
`

const ServicesDescWrapper = styled.div`
  margin-top: 91px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-top: 52px;
  }
`

const ServicesDesc = styled(Text)`
  max-width: 921px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: max-content;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('t5')};
  }
`

const CardsWrapper = styled.div`
  display: flex;
  margin-top: 112px;
  padding: 0px 90px;
  height: 240px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 0px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    height: auto;
    margin-top: 84px;
  }
`

const Card = styled(BoxShadow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px;
  height: 240px;
  width: 100%;
  background-color: white;

  &:not(:last-child) {
    margin-right: 24px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    &:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }
`

const CardTitle = styled(Text)`
  color: ${theme.palette.brightBlue};
`

const CardDescr = styled(Text)`
  color: ${theme.palette.middleGrey};

  @media (max-width: ${breakpoints.desktop - 1}px) {
    ${getStyles('h6')};
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: 506px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h8')};
  }
`
const ButtonSend = styled.button<{ isError: boolean; isDisabled: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 202px;
  height: fit-content;
  min-height: 48px;

  padding: 9px 23px;
  border-radius: 16px;
  color: ${({ isError, isDisabled }) => (isError || isDisabled ? `${theme.palette.coldGrey}` : `${theme.palette.blue}`)};
  background-color: ${({ isError, isDisabled }) => (isError || isDisabled ? 'black' : `${theme.palette.white}`)};
  border: 1px solid ${theme.palette.blue};

  transition: background-color ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.blue};

      * {
        color: ${theme.palette.white};
      }
    }
  }

  ${({ isError, isDisabled }) =>
    (isError || isDisabled) &&
    css`
      border: 1px solid ${isError ? `${theme.palette.red}` : `${theme.palette.coldGrey}`};
      pointer-events: none;
    `}
`

const TITLE = [
  'Наша компания специализируется на оказании консультационных услуг на территории ОАЭ',
  'Our firm renders consulting services in the UAE'
]

const MISSION = ['Мы можем видеть с высоты своего опыта', 'We can see from our experience']

const SERVICES = (isMobile: boolean): JSX.Element[] => [
  <>
    Оказываем {isMobile ? <br /> : null} консультационные услуги в области регистрации бизнеса на территории ОАЭ,
    налогового и юридического консультирования.
  </>,
  <>
    Key lines{isMobile ? <br /> : null} of our work include assistance with business registration in the UAE, and tax
    and legal support to companies and private individuals.
  </>
]

const SERVICES_DECR_ONE = [
  'Наша команда придерживается высоких стандартов качества в своей работе. Это является залогом реализации нашей корпоративной миссии.',
  'Our team is committed to the highest quality standards in our work. This is the key to the realization of our corporate mission.'
]
const SERVICES_DECR_TWO = [
  'Компании Группы TC – признанные лидеры в области оказания клиентам услуг по налоговому и юридическому консультированию. World Tax, Best Lawyers и Forbes – рейтинги, которые отметили высокий профессионализм команды.',
  "Companies of TC Group are acclaimed leaders in tax and legal consulting. The team's expertise is recognized by legal rankings World Tax, Best Lawyers and Forbes."
]

const CARDS_INFO = [
  [
    { title: '30+', descr: 'СПЕЦИАЛИСТОВ' },
    { title: '20+', descr: 'ЛЕТ ОПЫТА РАБОТЫ В ОБЛАСТИ ОКАЗАНИЯ КОНСУЛЬТАЦИОННЫХ УСЛУГ' }
  ],
  [
    { title: '30+', descr: 'EXPERTS' },
    { title: '20+', descr: 'YEARS PROVIDING CONSULTING SERVICES' }
  ]
]

const APPLICATION_TEXT = ['Оставить заявку', 'Contact Us']

export const AboutUs: FC = memo(() => {
  const { isMobile } = useDimensions()
  const { languageState } = useLanguage()

  const dispatch = useDispatch()

  const onApplicationClick = () => {
    dispatch(setModalOpen({ modalIsOpen: true, modalType: { type: 'application' } }))
  }

  return (
    <>
      <WrapperUpper>
        <Helicopter name="urist.png" />
        <Title>
          <Logo name="logo_light" isIcon />
          <StyledTitle variant="h2" color="white">
            {TITLE[languageState.number]}
          </StyledTitle>
        </Title>
        <WrapperMission>
          <MissionText variant="h3" color="white">
            {MISSION[languageState.number]}
          </MissionText>
          <ButtonSend isError={false} isDisabled={false} onClick={() => onApplicationClick()}>
            <Text variant="t4" color="#466BBC">
              {APPLICATION_TEXT[languageState.number]}
            </Text>
          </ButtonSend>
        </WrapperMission>
      </WrapperUpper>
      <WrapperLower>
        <Rectangle />
        <ServicesTitle variant="h1" bold>
          {SERVICES(isMobile)[languageState.number]}
        </ServicesTitle>
        <ServicesDescWrapper>
          <ServicesDesc variant="t3">{SERVICES_DECR_ONE[languageState.number]}</ServicesDesc>
          <ServicesDesc variant="t3">{SERVICES_DECR_TWO[languageState.number]}</ServicesDesc>
        </ServicesDescWrapper>
        <CardsWrapper>
          {CARDS_INFO[languageState.number].map((el) => (
            <Card key={el.title}>
              <CardTitle variant="h0">{el.title}</CardTitle>
              <CardDescr variant="h6" align="center">
                {el.descr}
              </CardDescr>
            </Card>
          ))}
        </CardsWrapper>
      </WrapperLower>
    </>
  )
})
