import { styled } from 'ui/styles'

export const PaginationItem = styled.button<{ active?: boolean }>`
  width: 40px;
  height: 44px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.active ? props.theme.palette.deepBlue : props.theme.palette.bluePastel)};
  transition: 200ms;
  cursor: pointer;

  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.deepBlue};

  &:hover {
    border-color: ${(props) => props.theme.palette.deepBlue};
  }

  &:active {
    opacity: 0.4;
  }
`
