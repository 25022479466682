import { FC } from 'react'
import { Client } from '../../data/clients'
import { Pattern } from '../../ui/Pattern/Pattern'
import { useLanguage } from 'app/shared'
import styles from './styles.module.scss'

import { AuthorBlock, AuthorInfo, Container, Content, Description, InfoBlock, Logo, LogoBlock, Title } from './ui'
import { Name, Post, FileLink } from '../../ui'

interface ClientCardProps {
  className?: string
  item: Client
  onClickDoc: () => void
}

export const ClientCard: FC<ClientCardProps> = ({ className, item, onClickDoc }) => {
  const { languageState } = useLanguage()

  return (
    <Container className={className}>
      <Content>
        <InfoBlock>
          <div>
            <Title>{item.title[languageState.number]}</Title>
            <Description>{item.description[languageState.number]}</Description>
          </div>
          {item.docs.length > 0 && (
            <AuthorBlock>
              <AuthorInfo>
                <Name>{item.docs[0].author[languageState.number]}</Name>
                <Post>{item.docs[0].post[languageState.number]}</Post>
              </AuthorInfo>
              {item.docs.length === 1 && <FileLink className={styles.FileLink} href={item.docs[0].link} />}
              {item.docs.length > 1 && <FileLink className={styles.FileLink} onClick={onClickDoc} />}
            </AuthorBlock>
          )}
        </InfoBlock>
        <LogoBlock>
          <Logo src={item.logo} />
        </LogoBlock>
      </Content>
      <Pattern className={styles.Pattern} />
    </Container>
  )
}
