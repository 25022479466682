import React from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { ApplicationE, ApplicationFormFieldsT } from 'data/application'
import { getStyles, styled, theme } from 'ui/styles'

const Input = styled(PhoneInput)<{ isValid: boolean; isNotEmpty: boolean }>`
  ${getStyles('t5')}
  .arrow {
    display: none;
  }

  &:focus-within {
    .flag-dropdown {
      border: 1px solid ${theme.palette.blue} !important;
    }
    .form-control {
      border: 1px solid ${theme.palette.blue} !important;
    }
  }
  @media (hover: hover) {
    &:hover {
      .form-control,
      .flag-dropdown {
        border: 1px solid white !important;
        box-shadow: 0 0 0px 1px ${theme.palette.blue}, 0px 4px 5px rgba(0, 0, 0, 0.15) !important;

        &:focus,
        &:active,
        &:target {
          border: 1px solid white !important;
        }
      }
    }
  }

  .selected-flag {
    padding: 0 0 0 16px;
    width: auto;

    &.open,
    &:focus {
      border-radius: 0px 8px 8px 0px !important;
    }

    &:hover {
      border-radius: 0px 8px 8px 0px;
      border-color: white !important;
    }
  }

  .form-control {
    ${getStyles('t5')}
    &.invalid-number {
      border-color: unset;
    }

    border: 1px solid
      ${({ isValid, isNotEmpty }) =>
        isValid ? (isNotEmpty ? theme.palette.primary : theme.palette.coldGrey) : theme.palette.red} !important;
    transition-property: color, border, box-shadow;
    transition-duration: ${theme.transition.hover}ms;

    @media (hover: hover) {
      &:hover {
        &:focus,
        &:active,
        &:target {
          border: 1px solid white;
        }
      }
    }

    &:focus,
    &:active,
    &:target {
      border: 1px solid ${theme.palette.blue} !important;
    }
  }

  .country {
    ${getStyles('t5')};
    font-family: ${theme.fontFamily};
  }

  .flag-dropdown {
    &.invalid-number {
      border-color: unset;
    }

    border: 1px solid
      ${({ isValid, isNotEmpty }) =>
        isValid ? (isNotEmpty ? theme.palette.primary : theme.palette.coldGrey) : theme.palette.red} !important;

    transition-property: color, border, box-shadow;
    transition-duration: ${theme.transition.hover}ms;
  }

  input {
    ::placeholder {
      color: ${theme.palette.blue};
    }
  }
`

const localization = { Russia: 'Россия', Ukraine: 'Україна' }

const phoneInputStyles = () => ({
  width: '100%',
  height: '40px',
  borderRadius: '8px',
  backgroundColor: 'white',
  transitionProperty: 'border, color',
  transitionDuration: `${theme.transition.hover}ms`,
  padding: '8px 16px',
  fontFamily: `${theme.fontFamily}, sans-serif`
})

const phoneButtonStyles = () => ({
  right: '0px',
  width: '52px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  background: 'white'
})

const phoneDropDownStyles = {
  left: '-250px'
}

type Props = {
  value: string
  isValid: boolean
  field: ControllerRenderProps<ApplicationFormFieldsT, ApplicationE>
}

export const PhoneField: React.FC<Props> = ({ isValid, value, field }) => {
  const isNotEmpty = Boolean(value)

  return (
    <Input
      country="ru"
      autoFormat={true}
      regions={['america', 'europe', 'asia']}
      localization={localization}
      inputStyle={phoneInputStyles()}
      buttonStyle={phoneButtonStyles()}
      dropdownStyle={phoneDropDownStyles}
      placeholder=""
      preferredCountries={['ru']}
      isNotEmpty={isNotEmpty}
      isValid={isValid}
      {...field}
    />
  )
}
