import { FC } from 'react'

import { ReactComponent as FileSvg } from 'ui/icons/file.svg'
import { styled } from 'ui/styles'

const FileCircle = styled.a`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgba(20, 31, 62, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: 300ms;

  &:hover {
    background-color: rgba(20, 31, 62, 0.16);
  }
  &:active {
    opacity: 0.4;
  }
`

interface FileLinkProps {
  className?: string
  href?: string
  onClick?: () => void
}

export const FileLink: FC<FileLinkProps> = ({ href, className, onClick }) => (
  <FileCircle className={className} href={href} target="_blank" onClick={onClick}>
    <FileSvg />
  </FileCircle>
)
