import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { configureStore } from 'configure-store'

import { GlobalStyles, theme, ThemeProvider } from 'ui/styles'
import { LanguageProvider } from 'app/shared'
import { App } from 'App'

const root = createRoot(document.getElementById('root')!)
const store = configureStore()

root.render(
  <ReduxProvider store={store}>
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </LanguageProvider>
  </ReduxProvider>
)
