type TServicesData = {
  title: string
  descr: string
  services: string[]
  icon: string
}

export const TITLE = [
  'Услуги',
  'Services',
]

export const DESCRIPRION = [
  'Мы помогаем нашим клиентам решать сложные задачи и развивать бизнес',
  'We help our clients solve complex problems and develop their business',
]

export const INCLUDE = [
  'Наши услуги по данному направлению включают в себя:',
  'Our services include:',
]

export const SERVICES_DATA: Array<TServicesData[]> = [
  [
    {
      title: 'Регистрация компании в ОАЭ и сопутствующие услуги',
      descr: 'Помогаем открыть или перевести бизнес на территорию ОАЭ.',
      icon: 'airplane',
      services: [
        '- Содействие в выборе наиболее оптимальной формы организации бизнеса на территории ОАЭ',
        '- Содействие в подготовке всех документов, необходимых для регистрации компании',
        '- Сопровождение в рамках коммуникации с уполномоченным органами',
        '- Сопровождение в рамках открытия корпоративных банковских счетов'
      ]
    },
    {
      title: 'Поддержка бизнеса в ОАЭ',
      descr: 'Помогаем вести бизнес на территории ОАЭ.',
      icon: 'hands',
      services: [
        '- Сопровождение в рамках трудоустройства сотрудников',
        '- Сопровождение в рамках получения ВНЖ',
        '- Иные сопутствующие услуги'
      ]
    },
    {
      title: 'Налоги и право',
      descr: 'Консультируем по правовым аспектам и вопросам национального и международного налогообложения.',
      icon: 'documents',
      services: [
        '- Консультирование по вопросам применения национального налогового законодательства (НДС, налог на прибыль)',
        '- Консультирование по вопросам международного налогообложения (СОИДН, MLI и т.д.)',
        '- Консультирование по вопросам применения валютного и санкционного законодательства',
        '- Консультирование по вопросам налогообложения КИК',
        '- Налоговые услуги частным клиентам',
        '- Сопровождение в рамках получения статуса налогового резидента ОАЭ',
        '- Иные сопутствующие услуги'
      ]
    }
  ],
  [
    {
      title: 'Company registration and related services in the UAE',
      descr: 'We assist clients in starting up or relocating their business to the UAE.',
      icon: 'airplane',
      services: [
        '- Assistance in choosing the most suitable form of organization in the UAE',
        '- Preparation of the documents  required for company registration',
        '- Support in communicating with competent authorities',
        '- Support in opening corporate bank accounts',
      ]
    },
    {
      title: 'Business support in UAE',
      descr: 'We advise on operating business in the UAE.',
      icon: 'hands',
      services: [
        '- Legal support of the staff recruitment process',
        '- Support in obtaining a residence permit',
        '- Other related services',
      ]
    },
    {
      title: 'Tax and Law',
      descr: 'We advise on legal issues, national tax legislation and international tax matters.',
      icon: 'documents',
      services: [
        'Advice on the application of national tax legislation (VAT, income tax)',
        'Advice on international taxation issues (DTTs, MLI, etc.)',
        'Advice on currency and sanctions legislation',
        'Advice on CFC taxation issues',
        'Tax advice to private clients',
        'Support in obtaining tax residency in the UAE',
        'Other related services',
      ]
    }
  ]
]