import { FC } from 'react'
import { styled } from 'ui/styles'
import { PaginationItem } from './PaginationItem'

const Container = styled.div`
  display: flex;
  gap: 16px;
`

interface PaginationProps {
  activePage: number
  onChange: (page: number) => void
  pageCount: number
}

export const Pagination: FC<PaginationProps> = ({ activePage, onChange, pageCount }) => {
  if (pageCount <= 1) return null

  return (
    <Container>
      {Array.from(Array(pageCount).keys()).map((item) => {
        return (
          <PaginationItem
            active={activePage === item}
            key={item}
            onClick={() => {
              onChange(item)
            }}
          >
            {item + 1}
          </PaginationItem>
        )
      })}
    </Container>
  )
}
