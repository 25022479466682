import { styled } from 'ui/styles'

export const AuthorBlock = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;

  @media (max-width: 1440px) {
    gap: 24px;
  }
`
export const AuthorInfo = styled.div`
  flex: 1;
`
