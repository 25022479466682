import { combineReducers } from 'redux'

import { ModalAction, modalReducer, ModalState, SectionAction, sectionReducer, SectionState } from 'store'
import { loaderReducer, LoaderState } from 'store/loader'

export type RootState = {
  section: SectionState
  modal: ModalState
  loader: LoaderState
}

export const createRootReducer = () =>
  combineReducers<RootState>({
    section: sectionReducer,
    modal: modalReducer,
    loader: loaderReducer,
  })

export type RootAction = SectionAction | ModalAction
