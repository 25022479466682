import { createStore, compose } from 'redux'

import { createRootReducer } from './root-reducer'

export const configureStore = () => {
  const composeEnhancers =
    (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const store = createStore(createRootReducer(), composeEnhancers())
  return store
}
