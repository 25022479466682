import { FC } from 'react'
import { useDispatch } from 'react-redux'

import { setModalOpen } from 'store'

import { breakpoints, useDimensions, useLanguage } from 'app/shared'
import { styled, theme } from 'ui/styles'

import { AppBarItem } from '../atoms'
import { PrimaryButton, Text } from 'ui/components'
import { LanguageBar } from './LanguageBar'
import { Links } from './Links'
import { sections } from 'app/features/sections/Sections'

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    position: fixed;
    flex-direction: column;
    left: 0px;
    top: ${({ isOpen }) => (isOpen ? '64px' : '-110%')};
    padding: 24px 16px;
    z-index: 99;
    width: 100%;
    background-color: white;
    border-radius: 0px 0px 8px 8px;
    align-items: start;

    overflow-x: hidden;

    transition: transform, ${theme.transition.hover}ms ease;
  }
`

const Items = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const APPLICATION_TEXT = ['Оставить заявку', 'Contact Us']

type Props = {
  burgerIsOpened: boolean
  handleItemClick: (i: number) => void
}

export const AppBarItems: FC<Props> = ({ burgerIsOpened, handleItemClick }) => {
  const dispatch = useDispatch()
  const { isMobile } = useDimensions()
  const { languageState } = useLanguage()

  const onApplicationClick = () => {
    dispatch(setModalOpen({ modalIsOpen: true, modalType: { type: 'application' } }))
  }

  return (
    <Wrapper isOpen={burgerIsOpened}>
      <Items>
        {sections.map((tile, i) => (
          <AppBarItem key={tile.id} title={tile.title[languageState.number]} onClick={() => handleItemClick(i)} />
        ))}
      </Items>
      <Links />
      {isMobile ? (
        <AppBarItem title={APPLICATION_TEXT[languageState.number]} onClick={() => onApplicationClick()} />
      ) : (
        <>
          <PrimaryButton onClick={() => onApplicationClick()}>
            <Text variant="t4" color="white">
              {APPLICATION_TEXT[languageState.number]}
            </Text>
          </PrimaryButton>
          <LanguageBar />
        </>
      )}
    </Wrapper>
  )
}
