import { styled } from 'ui/styles'

export const Segment = styled.div<{ rotated?: boolean; type: 'blue' | 'gray' }>`
  height: ${(props) => (props.type === 'gray' ? '12px' : '8px')};
  background-size: ${(props) => (props.type === 'gray' ? '37px' : '24px')};
  width: 100%;
  background-repeat: repeat;
  background-position: ${(props) => (props.type === 'gray' ? '-18px' : '-12px')};
  transform: ${(props) => (props.rotated ? 'rotateX(180deg)' : 'unset')};
`
