import { css } from 'styled-components'
import { styled } from 'ui/styles'

export const Divider = styled.div<{ withMargin?: boolean; color?: string }>`
  width: 100%;
  height: 1px;
  background: ${({ color }) => (color ? color : '#466BBC')};

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 24px 0;
    `}
`
