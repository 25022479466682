import { FC } from 'react'
import { Segment } from './Segment'

interface PatterProps {
  className?: string
  type?: 'gray' | 'blue'
}

export const Pattern: FC<PatterProps> = ({ className, type = 'gray' }) => {
  const backgroundImage =
    type === 'gray' ? `url(${require('./assets/pattern-gray.png')})` : `url(${require('./assets/pattern-blue.png')})`

  return (
    <div className={className} style={{ width: '100%' }}>
      <Segment
        type={type}
        style={{
          backgroundImage,
          backgroundPosition: 0
        }}
      />
      <Segment type={type} rotated style={{ backgroundImage }} />
    </div>
  )
}
