import { styled } from 'ui/styles'

export const LogoBlock = styled.div`
  width: 198px;
  height: 198px;

  @media (max-width: 1440px) {
    width: 270px;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 120px;
    border-bottom: 1px solid ${(props) => props.theme.palette.bluePastel};
  }
`
export const Logo = styled.img`
  width: 100%;
  object-position: center;
  object-fit: contain;
  height: 100%;
`