import { FC, ReactNode } from 'react'
import { styled } from 'ui/styles'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
`

const H1 = styled.h1`
  font-size: ${(props) => props.theme.typography.h1.fontSize};
  font-weight: ${(props) => props.theme.typography.h1.fontWeight};
  line-height: ${(props) => props.theme.typography.h1.lineHeight};

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.typography.h6.fontSize};
    font-weight: ${(props) => props.theme.typography.h6.fontWeight};
    line-height: ${(props) => props.theme.typography.h6.lineHeight};
  }
`

interface HeaderProps {
  title: string
  rightComponent?: ReactNode
}

export const Header: FC<HeaderProps> = ({ title, rightComponent }) => {
  return (
    <Container>
      <H1>{title}</H1>
      {rightComponent}
    </Container>
  )
}
