import { FC } from 'react'
import { styled } from 'ui/styles'

const Container = styled.button`
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;

  transition: 200ms;

  &:active {
    opacity: 0.4;
  }
`

interface CloseButtonProps {
  onClick: () => void
}

export const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M7 7L25 25" stroke="#141F3E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 25L25 7" stroke="#141F3E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </Container>
  )
}
