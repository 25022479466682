import { createReducer, ActionType } from 'typesafe-actions'

import * as loader from './actions'

export type LoaderAction = ActionType<typeof loader>

export type LoaderState = {
  isLoading: boolean
}

const initialState: LoaderState = {
  isLoading: false
}

export const loaderReducer = createReducer<LoaderState>(initialState).handleAction(
  loader.setLoader,
  (state: LoaderState, { payload }: any) => ({
    ...state,
    isLoading: payload
  })
)
